import React from "react";
interface IProps {
  action: string;
}
const Successful: React.FC<IProps> = ({ action }) => {
  return (
    <div className="vh-100 d-flex align-items-center justify-content-center">
      <div>
        <div className="">
          {action === "reply_guard" ? (
            <React.Fragment>
              <strong>Success!</strong> ReplyGuard has been successfully
              verified.
            </React.Fragment>
          ) : (
            <React.Fragment>
              <strong> Success!</strong> You have logged in successfully.
            </React.Fragment>
          )}
        </div>
        <div className="d-flex justify-content-center mt-2">
          <button
            type="button"
            className="close btn btn-primary"
            data-dismiss="alert"
            aria-label="Close"
            onClick={() => window.close()}
          >
            <span aria-hidden="true">&times; close</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Successful;
