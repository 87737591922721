import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import router from "./routes";
import { ToastContainer } from "react-toastify";
import { AppProvider } from "./contexts/AppContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./styles/style.css";

const Index = () => {
  const location = window.location;

  // Check if the current path is '/passwordless'
  const isPasswordlessPath = location.pathname === "/passwordless";

  return (
    <>
      {!isPasswordlessPath ? (
        <AppProvider>
          <div>
            <RouterProvider router={router} />
            <ToastContainer />
          </div>
        </AppProvider>
      ) : (
        <RouterProvider router={router} />
      )}
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(<Index />);
