import React, { useEffect, useState } from "react";
const UserLocation = () => {
  const [sharedLocation, setSharedLocation] = useState(null);
  const [isLocationShared, setIsLocationShared] = useState(false);
  const [error, setError] = useState(null);
  const [locationBlockedMessage, setLocationBlockedMessage] = useState(false);

  //   functions
  const handleGeolocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const coords = {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          };
          if (position.coords.latitude && position.coords.longitude) {
            window.opener.postMessage(coords, "*");
            setIsLocationShared(true);
            setSharedLocation(coords);

            window.close();
          } else {
            setSharedLocation(null);
          }
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            // User denied the location request
            setLocationBlockedMessage(true);
          } else {
            setError("Error retrieving location: " + error.message);
          }
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
      setTimeout(() => {
        window.close();
      }, 500);
    }
  };

  useEffect(() => {
    handleGeolocation();
  }, []);
  return (
    <div className="vh-100 w-100 d-flex align-items-center justify-content-center">
      <div className="card w-100 h-100">
        <div className="card-header bg-primary">
          <div className="d-flex justify-content-center">
            <div
              className="rounded-circle border d-inline-block bg-white p-2"
              style={{ marginBottom: "-30px" }}
            >
              <img
                src="./assets/images/final_logo.png"
                alt="witway logo"
                height="36px"
                width="36px"
              />
            </div>
          </div>
        </div>
        <div className="card-body my-4">
          {!isLocationShared && !locationBlockedMessage && (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <h4 className="h4 text-center">Sharing your geolocation.</h4>
              <div
                id="verified_user_loader"
                className="justify-content-center mt-5"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height="40px"
                  viewBox="0 -960 960 960"
                  width="40px"
                  fill="#0000F5"
                >
                  <path d="M480-80q-83.33 0-156.33-31.17-73-31.16-127.17-85.33t-85.33-127.17Q80-396.67 80-480q0-83.67 31.17-156.5 31.16-72.83 85.33-127t127.17-85.33Q396.67-880 480-880q13.67 0 23.5 9.83 9.83 9.84 9.83 23.5 0 13.67-9.83 23.5-9.83 9.84-23.5 9.84-138.33 0-235.83 97.5T146.67-480q0 138.33 97.5 235.83T480-146.67q138.33 0 235.83-97.5T813.33-480q0-13.67 9.84-23.5 9.83-9.83 23.5-9.83 13.66 0 23.5 9.83Q880-493.67 880-480q0 83.33-31.17 156.33-31.16 73-85.33 127.17t-127 85.33Q563.67-80 480-80Z" />
                </svg>
              </div>
            </div>
          )}
          <p className="text-center small">
            {locationBlockedMessage ? (
              <div className="d-flex justify-content-center">
                <div>
                  <p
                    style={{
                      fontWeight: 500,
                      fontSize: 15,
                    }}
                  >
                    <b>
                      It seems that location access is blocked. Please follow
                      these steps to allow access:
                    </b>
                  </p>
                  <ol>
                    <li>
                      <p>Click on the padlock icon in the address bar.</p>
                    </li>
                    <li>
                      <p>Find the "Location" setting and set it to "Allow".</p>
                    </li>
                    <li>
                      <p>Clieck the Refresh Page button.</p>
                    </li>
                  </ol>
                  <div className="d-flex justify-content-center">
                    <button
                      className="btn btn-sm btn-primary"
                      onClick={() => window.location.reload()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        height="24px"
                        viewBox="0 -960 960 960"
                        width="24px"
                        fill="#fff"
                      >
                        <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                      </svg>
                      Refresh
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <></>
            )}
          </p>
          {error && (
            <div className="text-center d-flex justify-content-center">
              <div>
                <p className="small">{error}</p>
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => window.location.reload()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24px"
                      viewBox="0 -960 960 960"
                      width="24px"
                      fill="#fff"
                    >
                      <path d="M480-160q-134 0-227-93t-93-227q0-134 93-227t227-93q69 0 132 28.5T720-690v-110h80v280H520v-80h168q-32-56-87.5-88T480-720q-100 0-170 70t-70 170q0 100 70 170t170 70q77 0 139-44t87-116h84q-28 106-114 173t-196 67Z" />
                    </svg>
                    Refresh
                  </button>
                </div>
              </div>
            </div>
          )}
          {sharedLocation && (
            <div className="d-flex align-items-center justify-content-center">
              <div>
                <h4 className="h4 text-center">
                  Thank you for sharing your location.
                </h4>
                {/* <p className="text-center">{sharedLocation}</p> */}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserLocation;
