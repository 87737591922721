export function getCountByCountry(participants, targetContinent) {
    const participantsInContinent = participants.filter(participant => participant.continent === targetContinent);

    const participantCountByCountry = [];
    const countryLabels = [];
    const usersList = [];

    participantsInContinent.forEach(participant => {
        const { country, userName } = participant;
        if (!participantCountByCountry[country]) {
            participantCountByCountry[country] = 1;
            countryLabels.push(country);
            usersList.push(userName);
        } else {
            participantCountByCountry[country]++;
            // countryLabels.push(country);
            var index = countryLabels.indexOf(country);
            if (index !== -1) {
                usersList[index] = usersList[index] + ", " + userName;
            } else {
                usersList.push(userName);
            }

        }
    });

    return {
        count: Object.values(participantCountByCountry),
        countryLabels: countryLabels,
        usersList: usersList
    };
}