const continents = [
  {
    name: "North America",
    latRange: [7.1666, 83.6753],
    lonRange: [-169.9514, -34.5661],
  },
  {
    name: "South America",
    latRange: [-56.186938, 12.436556],
    lonRange: [-81.410781, -35.833672],
  },
  {
    name: "Europe",
    latRange: [35.813163, 71.5388],
    lonRange: [-25.03125, 44.59375],
  },
  {
    name: "Africa",
    latRange: [-35.817813, 37.216613],
    lonRange: [-25.03125, 51.5625],
  },
  {
    name: "Asia",
    latRange: [-7.013667, 77.837451],
    lonRange: [44.59375, 180.0],
  },
  {
    name: "Australia",
    latRange: [-47.576526, -10.668185],
    lonRange: [113.027344, 153.984375],
  },
  { name: "Antarctica", latRange: [-90, -60], lonRange: [-180, 180] },
];
const getContinentName = (latitude: number, longitude: number) => {
  for (const continent of continents) {
    const { name, latRange, lonRange } = continent;
    const isLatInRange = latitude >= latRange[0] && latitude <= latRange[1];
    const isLonInRange = longitude >= lonRange[0] && longitude <= lonRange[1];

    if (isLatInRange && isLonInRange) {
      return name;
    }
  }
  return "Unknown";
};

// country continent 
// Define an enum named 'Continent'
enum Continent {
  AF = 'Africa',
  AS = 'Asia',
  EU = 'Europe',
  NA = 'North America',
  SA = 'South America',
  OC = 'Oceania',
  AN = 'Antarctica',
  UNK = 'Unknown'
}

// Define a mapping object for country codes to continents using short names as keys
const CountryContinent: Record<string, Continent> = {
  'AF': Continent.AF,
  'AX': Continent.EU,
  'AL': Continent.EU,
  'DZ': Continent.AF,
  'AS': Continent.OC,
  'AD': Continent.EU,
  'AO': Continent.AF,
  'AI': Continent.NA,
  'AQ': Continent.AN,
  'AG': Continent.NA,
  'AR': Continent.SA,
  'AM': Continent.AS,
  'AW': Continent.NA,
  'AU': Continent.OC,
  'AT': Continent.EU,
  'AZ': Continent.AS,
  'BS': Continent.NA,
  'BH': Continent.AS,
  'BD': Continent.AS,
  'BB': Continent.NA,
  'BY': Continent.EU,
  'BE': Continent.EU,
  'BZ': Continent.NA,
  'BJ': Continent.AF,
  'BM': Continent.NA,
  'BT': Continent.AS,
  'BO': Continent.SA,
  'BQ': Continent.NA,
  'BA': Continent.EU,
  'BW': Continent.AF,
  'BV': Continent.AN,
  'BR': Continent.SA,
  'IO': Continent.AS,
  'VG': Continent.NA,
  'BN': Continent.AS,
  'BG': Continent.EU,
  'BF': Continent.AF,
  'BI': Continent.AF,
  'KH': Continent.AS,
  'CM': Continent.AF,
  'CA': Continent.NA,
  'CV': Continent.AF,
  'KY': Continent.NA,
  'CF': Continent.AF,
  'TD': Continent.AF,
  'CL': Continent.SA,
  'CN': Continent.AS,
  'CX': Continent.AS,
  'CC': Continent.AS,
  'CO': Continent.SA,
  'KM': Continent.AF,
  'CD': Continent.AF,
  'CG': Continent.AF,
  'CK': Continent.OC,
  'CR': Continent.NA,
  'CI': Continent.AF,
  'HR': Continent.EU,
  'CU': Continent.NA,
  'CW': Continent.NA,
  'CY': Continent.AS,
  'CZ': Continent.EU,
  'DK': Continent.EU,
  'DJ': Continent.AF,
  'DM': Continent.NA,
  'DO': Continent.NA,
  'EC': Continent.SA,
  'EG': Continent.AF,
  'SV': Continent.NA,
  'GQ': Continent.AF,
  'ER': Continent.AF,
  'EE': Continent.EU,
  'ET': Continent.AF,
  'FO': Continent.EU,
  'FK': Continent.SA,
  'FJ': Continent.OC,
  'FI': Continent.EU,
  'FR': Continent.EU,
  'GF': Continent.SA,
  'PF': Continent.OC,
  'TF': Continent.AN,
  'GA': Continent.AF,
  'GM': Continent.AF,
  'GE': Continent.AS,
  'DE': Continent.EU,
  'GH': Continent.AF,
  'GI': Continent.EU,
  'GR': Continent.EU,
  'GL': Continent.NA,
  'GD': Continent.NA,
  'GP': Continent.NA,
  'GU': Continent.OC,
  'GT': Continent.NA,
  'GG': Continent.EU,
  'GN': Continent.AF,
  'GW': Continent.AF,
  'GY': Continent.SA,
  'HT': Continent.NA,
  'HM': Continent.AN,
  'VA': Continent.EU,
  'HN': Continent.NA,
  'HK': Continent.AS,
  'HU': Continent.EU,
  'IS': Continent.EU,
  'IN': Continent.AS,
  'ID': Continent.AS,
  'IR': Continent.AS,
  'IQ': Continent.AS,
  'IE': Continent.EU,
  'IM': Continent.EU,
  'IL': Continent.AS,
  'IT': Continent.EU,
  'JM': Continent.NA,
  'JP': Continent.AS,
  'JE': Continent.EU,
  'JO': Continent.AS,
  'KZ': Continent.AS,
  'KE': Continent.AF,
  'KI': Continent.OC,
  'KP': Continent.AS,
  'KR': Continent.AS,
  'KW': Continent.AS,
  'KG': Continent.AS,
  'LA': Continent.AS,
  'LV': Continent.EU,
  'LB': Continent.AS,
  'LS': Continent.AF,
  'LR': Continent.AF,
  'LY': Continent.AF,
  'LI': Continent.EU,
  'LT': Continent.EU,
  'LU': Continent.EU,
  'MO': Continent.AS,
  'MK': Continent.EU,
  'MG': Continent.AF,
  'MW': Continent.AF,
  'MY': Continent.AS,
  'MV': Continent.AS,
  'ML': Continent.AF,
  'MT': Continent.EU,
  'MH': Continent.OC,
  'MQ': Continent.NA,
  'MR': Continent.AF,
  'MU': Continent.AF,
  'YT': Continent.AF,
  'MX': Continent.NA,
  'FM': Continent.OC,
  'MD': Continent.EU,
  'MC': Continent.EU,
  'MN': Continent.AS,
  'ME': Continent.EU,
  'MS': Continent.NA,
  'MA': Continent.AF,
  'MZ': Continent.AF,
  'MM': Continent.AS,
  'NA': Continent.AF,
  'NR': Continent.OC,
  'NP': Continent.AS,
  'NL': Continent.EU,
  'NC': Continent.OC,
  'NZ': Continent.OC,
  'NI': Continent.NA,
  'NE': Continent.AF,
  'NG': Continent.AF,
  'NU': Continent.OC,
  'NF': Continent.OC,
  'MP': Continent.OC,
  'NO': Continent.EU,
  'OM': Continent.AS,
  'PK': Continent.AS,
  'PW': Continent.OC,
  'PS': Continent.AS,
  'PA': Continent.NA,
  'PG': Continent.OC,
  'PY': Continent.SA,
  'PE': Continent.SA,
  'PH': Continent.AS,
  'PN': Continent.OC,
  'PL': Continent.EU,
  'PT': Continent.EU,
  'PR': Continent.NA,
  'QA': Continent.AS,
  'RE': Continent.AF,
  'RO': Continent.EU,
  'RU': Continent.EU,
  'RW': Continent.AF,
  'BL': Continent.NA,
  'SH': Continent.AF,
  'KN': Continent.NA,
  'LC': Continent.NA,
  'MF': Continent.NA,
  'PM': Continent.NA,
  'VC': Continent.NA,
  'WS': Continent.OC,
  'SM': Continent.EU,
  'ST': Continent.AF,
  'SA': Continent.AS,
  'SN': Continent.AF,
  'RS': Continent.EU,
  'SC': Continent.AF,
  'SL': Continent.AF,
  'SG': Continent.AS,
  'SX': Continent.NA,
  'SK': Continent.EU,
  'SI': Continent.EU,
  'SB': Continent.OC,
  'SO': Continent.AF,
  'ZA': Continent.AF,
  'GS': Continent.AN,
  'SS': Continent.AF,
  'ES': Continent.EU,
  'LK': Continent.AS,
  'SD': Continent.AF,
  'SR': Continent.SA,
  'SJ': Continent.EU,
  'SZ': Continent.AF,
  'SE': Continent.EU,
  'CH': Continent.EU,
  'SY': Continent.AS,
  'TW': Continent.AS,
  'TJ': Continent.AS,
  'TZ': Continent.AF,
  'TH': Continent.AS,
  'TL': Continent.AS,
  'TG': Continent.AF,
  'TK': Continent.OC,
  'TO': Continent.OC,
  'TT': Continent.NA,
  'TN': Continent.AF,
  'TR': Continent.AS,
  'TM': Continent.AS,
  'TC': Continent.NA,
  'TV': Continent.OC,
  'UG': Continent.AF,
  'UA': Continent.EU,
  'AE': Continent.AS,
  'GB': Continent.EU,
  'US': Continent.NA,
  'UM': Continent.OC,
  'VI': Continent.NA,
  'UY': Continent.SA,
  'UZ': Continent.AS,
  'VU': Continent.OC,
  'VE': Continent.SA,
  'VN': Continent.AS,
  'WF': Continent.OC,
  'EH': Continent.AF,
  'YE': Continent.AS,
  'ZM': Continent.AF,
  'ZW': Continent.AF,
  'UNK': Continent.UNK
};


function getContinentByCountryCode(countrySortName: string) {
  if (countrySortName) {
    return CountryContinent[countrySortName];
  } else {
    return "Unknown";
  }
}
export { getContinentName, getContinentByCountryCode };
