export const constants = {
  UPDATE_APP_VIEW: "/api/meeting/updateAppView",
  MEETING_TARGETED_NOTIFICATION: "/api/meeting/targetedNotification",
  USER_ROLE_ORGANIZER: "organizer",
  GET_MEETING_INFO: "/api/meeting/meetingdetails?meetingID=",
  UPDATE_USER_LOCATION: "/api/meeting/",
  SEND_MAIL_TO_ORGANIZER: "api/verificationCode/send-mail",
  CHECK_VERIFICATION_CODE: "api/verificationCode/verify-verification-code",
  GET_VERIFICATION_CODE: "api/verificationCode/check-verification-code",
  PRESENTATION_REQUEST: "api/entraverification/presentation-request",
  REQUEST_CALLBACK: "api/callback/request-status",
  CHECK_USER_VERIFIED: "api/entraverification/check-user-verified"
};
