import Swal from "sweetalert2";
/**
 *
 * register
 */
async function handleRegisterSubmit(
    organizationId,
    idtoken,
    handleRegistration
) {
    // possible values: none, direct, indirect
    let attestation_type = "indirect";
    // possible values: <empty>, platform, cross-platform
    let authenticator_attachment = "";

    // possible values: preferred, required, discouraged
    let user_verification = "preferred";

    // possible values: discouraged, preferred, required
    let residentKey = "discouraged";

    // prepare form post data
    var data = new FormData();
    data.append("attType", attestation_type);
    data.append("authType", authenticator_attachment);
    data.append("userVerification", user_verification);
    data.append("residentKey", residentKey);

    // send to server for registering
    let makeCredentialOptions;
    try {
        makeCredentialOptions = await fetchMakeCredentialOptions(
            data,
            organizationId,
            idtoken
        );
    } catch (e) {
        console.error(e);
        let msg = "Something went really wrong";
        showErrorAlert(msg);
    }

    // console.log("Credential Options Object", makeCredentialOptions);
    if (!makeCredentialOptions) {
        // console.log("Error creating credential options");
        // console.log(makeCredentialOptions.errorMessage);
        showErrorAlert("Something went really wrong");
        return;
    }
    if (!makeCredentialOptions && makeCredentialOptions.status !== "ok") {
        // console.log("Error creating credential options");
        // console.log(makeCredentialOptions.errorMessage);
        showErrorAlert(makeCredentialOptions.errorMessage);
        return;
    }

    try {
        // Turn the challenge back into the accepted format of padded base64
        makeCredentialOptions.challenge = coerceToArrayBuffer(
            makeCredentialOptions.challenge
        );
        // Turn ID into a UInt8Array Buffer for some reason
        makeCredentialOptions.user.id = coerceToArrayBuffer(
            makeCredentialOptions.user.id
        );

        makeCredentialOptions.excludeCredentials =
            makeCredentialOptions.excludeCredentials.map((c) => {
                c.id = coerceToArrayBuffer(c.id);
                return c;
            });

    }
    catch (error) {
        console.error(error);
    }

    if (
        makeCredentialOptions.authenticatorSelection.authenticatorAttachment ===
        null
    )
        makeCredentialOptions.authenticatorSelection.authenticatorAttachment =
            undefined;

    // console.log("Credential Options Formatted", makeCredentialOptions);

    Swal.fire({
        title: "Registering...",
        text: "Tap your security key to finish registration.",
        imageUrl: "/images/securitykey.min.svg",
        showCancelButton: true,
        showConfirmButton: false,
        focusConfirm: false,
        focusCancel: false,
        icon: "info",
    });

    // console.log("Creating PublicKeyCredential...");

    let newCredential;
    try {
        newCredential = await navigator.credentials.create({
            publicKey: makeCredentialOptions,
        });
    } catch (e) {
        var msg =
            "Could not create credentials in browser. Probably because the username is already registered with your authenticator. Please change username or authenticator.";
        showErrorAlert(msg, e);
    }

    // console.log("PublicKeyCredential Created", newCredential);

    try {
        if (newCredential && organizationId && idtoken) {
            registerNewCredential(
                newCredential,
                organizationId,
                idtoken,
                handleRegistration
            );
        }
    } catch (e) {
        showErrorAlert(e.message ? e.message : e);
    }
}

async function fetchMakeCredentialOptions(formData, orgid, idtoken) {
    // REACT_APP_WITWAY_AUTH_URL = https://auth.witway.com
    let response = await fetch(
        `${process.env.REACT_APP_WITWAY_AUTH_URL}/makeCredentialOptions`,
        {
            method: "POST", // or 'PUT'
            body: formData, // data can be `string` or {object}!
            credentials: "include",
            headers: {
                Accept: "application/json",
                OrganizationId: orgid,
                Authorization: "Bearer " + idtoken,
            },
        }
    );

    let data = await response.json();
    if (!data.status) {
        // Add status based on the response status
        if (response.ok) {
            data.status = "ok";
        } else {
            data.status = "error";
        }
    }
    // data.rp.id = window.location.hostname;
    return data;
}

// This should be used to verify the auth data with the server
async function registerNewCredential(
    newCredential,
    organizationId,
    idtoken,
    handleRegistration
) {
    // Move data into Arrays incase it is super long
    let attestationObject = new Uint8Array(
        newCredential.response.attestationObject
    );
    let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
    let rawId = new Uint8Array(newCredential.rawId);

    const data = {
        id: newCredential.id,
        rawId: coerceToBase64Url(rawId),
        type: newCredential.type,
        extensions: newCredential.getClientExtensionResults(),
        response: {
            AttestationObject: coerceToBase64Url(attestationObject),
            clientDataJSON: coerceToBase64Url(clientDataJSON),
            transports: newCredential.response.getTransports(),
        },
    };

    let response;
    try {
        response = await registerCredentialWithServer(
            data,
            organizationId,
            idtoken
        );
    } catch (e) {
        showErrorAlert(e);
    }

    // console.log("Credential Object", response);

    // show error
    if (response.status !== "ok") {
        // console.log("Error creating credential");
        // console.log(response.errorMessage);
        showErrorAlert(response.errorMessage);
        return;
    }

    // show success
    Swal.fire({
        title: "Registration Successful!",
        text: "You've registered successfully.",
        icon: "success",
        timer: 2000,
    });
    handleRegistration(true);
    // redirect to dashboard?
    //window.location.href = "/dashboard/" + state.user.displayName;
}

async function registerCredentialWithServer(formData, orgid, idtoken) {
    // REACT_APP_WITWAY_AUTH_URL = https://auth.witway.com
    let response = await fetch(
        `${process.env.REACT_APP_WITWAY_AUTH_URL}/makeCredential`,
        {
            method: "POST", // or 'PUT'
            body: JSON.stringify(formData), // data can be `string` or {object}!
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                OrganizationId: orgid,
                Authorization: "Bearer " + idtoken,
            },
        }
    );

    let data = await response.json();
    if (!data.status) {
        // Add status based on the response status
        if (response.ok) {
            data.status = "ok";
        } else {
            data.status = "error";
        }
    }
    return data;
}
/**
 * signin
 */
async function handleSignInSubmit(orgid, idtoken, setWitwayToken) {
    // prepare form post data
    var formData = new FormData();

    // send to server for registering
    let makeAssertionOptions;
    try {
        var res = await fetch(
            `${process.env.REACT_APP_WITWAY_AUTH_URL}/assertionOptions`,
            {
                method: "POST", // or 'PUT'
                body: formData, // data can be `string` or {object}!
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    OrganizationId: orgid,
                    Authorization: "Bearer " + idtoken,
                },
            }
        );

        makeAssertionOptions = await res.json();
        if (makeAssertionOptions && !makeAssertionOptions.status) {
            // Add status based on the response status
            if (res.ok) {
                makeAssertionOptions.status = "ok";
            } else {
                makeAssertionOptions.status = "error";
            }
        }
    } catch (e) {
        showErrorAlert("Request to server failed", e);
    }

    // console.log("Assertion Options Object", makeAssertionOptions);

    // show options error to user
    if (makeAssertionOptions && makeAssertionOptions.status !== "ok") {
        // console.log("Error creating assertion options");
        // console.log(makeAssertionOptions.errorMessage);
        showErrorAlert(makeAssertionOptions.errorMessage);
        return;
    }

    try {
        // todo: switch this to coercebase64
        const challenge = makeAssertionOptions.challenge
            .replace(/-/g, "+")
            .replace(/_/g, "/");
        makeAssertionOptions.challenge = Uint8Array.from(atob(challenge), (c) =>
            c.charCodeAt(0)
        );

        // fix escaping. Change this to coerce
        makeAssertionOptions.allowCredentials.forEach(function (listItem) {
            var fixedId = listItem.id.replace(/\_/g, "/").replace(/\-/g, "+");
            listItem.id = Uint8Array.from(atob(fixedId), (c) => c.charCodeAt(0));
        });
    } catch (error) {
        console.error(error);
    }
    // console.log("Assertion options", makeAssertionOptions);

    Swal.fire({
        title: "Logging In...",
        text: "Tap your security key to login.",
        imageUrl: "/images/securitykey.min.svg",
        showCancelButton: true,
        showConfirmButton: false,
        focusConfirm: false,
        focusCancel: false,
        icon: "warning",
    });

    // ask browser for credentials (browser will ask connected authenticators)
    let credential;
    try {
        credential = await navigator.credentials.get({
            publicKey: makeAssertionOptions,
        });
    } catch (err) {
        showErrorAlert(err.message ? err.message : err);
    }

    try {
        await verifyAssertionWithServer(credential, orgid, idtoken, setWitwayToken);
    } catch (e) {
        showErrorAlert("Could not verify assertion", e);
    }
}

/**
 * Sends the credential to the the FIDO2 server for assertion
 * @param {any} assertedCredential
 */
async function verifyAssertionWithServer(
    assertedCredential,
    orgid,
    idtoken,
    setWitwayToken
) {
    // Move data into Arrays incase it is super long
    let authData = new Uint8Array(assertedCredential.response.authenticatorData);
    let clientDataJSON = new Uint8Array(
        assertedCredential.response.clientDataJSON
    );
    let rawId = new Uint8Array(assertedCredential.rawId);
    let sig = new Uint8Array(assertedCredential.response.signature);

    const data = {
        id: assertedCredential.id,
        rawId: coerceToBase64Url(rawId),
        type: assertedCredential.type,
        extensions: assertedCredential.getClientExtensionResults(),
        response: {
            authenticatorData: coerceToBase64Url(authData),
            clientDataJSON: coerceToBase64Url(clientDataJSON),
            signature: coerceToBase64Url(sig),
        },
    };

    let response;
    try {
        let res = await fetch(
            `${process.env.REACT_APP_WITWAY_AUTH_URL}/makeAssertion`,
            {
                method: "POST", // or 'PUT'
                body: JSON.stringify(data), // data can be `string` or {object}!
                credentials: "include",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    OrganizationId: orgid,
                    Authorization: "Bearer " + idtoken,
                },
            }
        );
        response = await res.json();
        if (!response.status) {
            // Add status based on the response status
            if (res.ok) {
                response.status = "ok";
            } else {
                response.status = "error";
            }
        }
    } catch (e) {
        showErrorAlert("Request to server failed", e);
        throw e;
    }

    // show error
    if (response.status !== "ok") {
        // console.log("Error doing assertion");
        // console.log(response.errorMessage);
        showErrorAlert(response.errorMessage);
        return;
    }

    // show success message
    await Swal.fire({
        title: "Logged In!",
        text: "You're logged in successfully.",
        icon: "success",
        timer: 2000,
    });
    setWitwayToken(response);
}
/**
 * helpers
 */

let coerceToArrayBuffer = function (thing, name) {
    try {
        if (typeof thing === "string") {
            // base64url to base64
            thing = thing.replace(/-/g, "+").replace(/_/g, "/");

            // base64 to Uint8Array
            var str = window.atob(thing);
            var bytes = new Uint8Array(str.length);
            for (var i = 0; i < str.length; i++) {
                bytes[i] = str.charCodeAt(i);
            }
            thing = bytes;
        }

        // Array to Uint8Array
        if (Array.isArray(thing)) {
            thing = new Uint8Array(thing);
        }

        // Uint8Array to ArrayBuffer
        if (thing instanceof Uint8Array) {
            thing = thing.buffer;
        }

        // error if none of the above worked
        if (!(thing instanceof ArrayBuffer)) {
            throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
        }

        return thing;
    } catch (error) {
        console.error(error);
    }
};

let coerceToBase64Url = function (thing) {
    try {
        // Array or ArrayBuffer to Uint8Array
        if (Array.isArray(thing)) {
            thing = Uint8Array.from(thing);
        }

        if (thing instanceof ArrayBuffer) {
            thing = new Uint8Array(thing);
        }

        // Uint8Array to base64
        if (thing instanceof Uint8Array) {
            var str = "";
            var len = thing.byteLength;

            for (var i = 0; i < len; i++) {
                str += String.fromCharCode(thing[i]);
            }
            thing = window.btoa(str);
        }

        if (typeof thing !== "string") {
            throw new Error("could not coerce to string");
        }

        // base64 to base64url
        // NOTE: "=" at the end of challenge is optional, strip it off here
        thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

        return thing;

    } catch (error) {
        console.error(error);
    }
};

function showErrorAlert(message, error) {
    let footermsg = "";
    if (error) {
        footermsg = "exception:" + error.toString();
    }
    Swal.fire({
        icon: "error",
        title: "Error",
        text: message,
        footer: footermsg,
        //footer: '<a href>Why do I have this issue?</a>'
    });
    setTimeout(() => {
        window.close();
    }, 3000);
}

function detectFIDOSupport() {
    try {
        if (
            window.PublicKeyCredential === undefined ||
            typeof window.PublicKeyCredential !== "function"
        ) {
            //$('#register-button').attr("disabled", true);
            //$('#login-button').attr("disabled", true);
            var el = document.getElementById("notSupportedWarning");
            if (el) {
                el.style.display = "block";
            }
            return;
        }
    } catch (error) {
        console.error(error);
    }
}

/**
 *
 * Get a form value
 * @param {any} selector
 */
function value(selector) {
    var el = document.querySelector(selector);
    if (el.type === "checkbox") {
        return el.checked;
    }
    return el.value;
}
export { handleRegisterSubmit, handleSignInSubmit };
