import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import PasswordLess from "./pages/PasswordLess";
import UserLocation from "./pages/UserLocation";
const App = lazy(() => import("./App"));
const Configure = lazy(() => import("./pages/Configure"));
const Notification = lazy(() => import("./pages/Notification"));
const ExternalNotification = lazy(() => import("./pages/ExternalNotification"));
const NotFound = lazy(() => import("./pages/NotFound"));
const VIO = lazy(() => import("./pages/Vio"));

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/notification",
    element: <Notification />,
  },
  {
    path: "/externalnotification",
    element: <ExternalNotification />,
  },
  {
    path: "/configure",
    element: <Configure />,
  },
  {
    path: "/passwordless",
    element: <PasswordLess />,
  },
  {
    path: "/get-location",
    element: <UserLocation />,
  },
  {
    path: "/28eca79a-1ae4-4c39-83fb-bbf1adc8ec0f",
    element: <VIO />,
  },
  {
    path: "*",
    element: <NotFound theme={""} />,
  },
]);

export default router;
