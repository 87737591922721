import axios from "axios";
import { constants } from "./constants";
import { IParticipants, ITargetNotification, IUpdateAppView, IUpdateUserLocation } from "../types/interface/IParticipant";

const loadParticipants = async (
  meetingID: string | undefined,
  requestedBy: string | undefined
): Promise<IParticipants> => {
  if (!meetingID || !requestedBy) {
    console.error("MeetingID or requestedBy is undefined.");
  }

  try {
    const response = await axios.get(
      `/api/Meeting/${meetingID}/participants/${requestedBy}`
    );
    return response.data as IParticipants;
  } catch (error) {
    console.error("Error loading participants:", error);
    throw error;
  }
};

async function updateAppView(payload: IUpdateAppView) {
  return await axios
    .patch(`${constants.UPDATE_APP_VIEW}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("Error occured while updating app view", error);
    });
}
async function targetedNotification(payload: ITargetNotification) {
  return await axios
    .post(`${constants.MEETING_TARGETED_NOTIFICATION}`, payload)
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log("Error occured while sending notification", error);
    });
}

async function updateUserLocation(meetingId: string, payload: IUpdateUserLocation) {
  return await axios.post(`${constants.UPDATE_USER_LOCATION + meetingId}/saveLocation`, payload).then((res) => {
    return res;
  }).catch(error => {
    console.error(error);
  })
}

export { loadParticipants, updateAppView, targetedNotification, updateUserLocation };
