import { IParticipant } from "../types/interface/IParticipant";
import { getContinentByCountryCode, getContinentName } from "./ContinentHelper";
import { getLastValueFromString } from "./StringHelpers";

// Function to count users with the same longitude and latitude
export function countUsersByLocation(participants: IParticipant[], type = null) {
    const result: any = [];

    participants.forEach((participant: IParticipant) => {
        let { latitude, longitude, country, continent, countryCode } = participant;
        continent = getContinentByCountryCode(countryCode);
        // Check if there is an existing entry with the same longitude and latitude
        const existingEntry = result.find(
            (entry: { latitude: string; longitude: string; users: IParticipant[]; count: number; markerLabel?: string; country?: string; continent?: string }) => {
                return type === "country" ? getLastValueFromString(entry.country, " ") === getLastValueFromString(country, " ") : type === "continent" ? entry.continent === continent : entry.latitude === latitude && entry.longitude === longitude
            }

        );

        if (existingEntry) {
            // If an entry exists, add the participant to the 'users' array
            existingEntry.count += 1;
            existingEntry.users.push(participant);
        } else {
            // If no entry exists, create a new entry
            result.push({
                count: 1,
                latitude,
                longitude,
                users: [participant],
                country,
                continent,
                countryCode
            });
        }
    });

    // Modify the result to concatenate usernames with commas if there are multiple participants
    result.forEach((entry: { users: IParticipant[]; markerLabel?: string }) => {
        if (entry.users.length > 1) {
            entry.markerLabel = entry.users.map((user) => user.userName).join(", ");
        } else {
            entry.markerLabel = entry.users[0].userName;
        }
    });

    return result;
}

