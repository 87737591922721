import axios from "axios";
import React, { useEffect, useState } from "react";
import {
    handleRegisterSubmit,
    handleSignInSubmit,
} from "../helper/webAuthnHelper";
import Successful from "../components/Successful";
import Loading from "../components/Loading";
import { useLocation } from "react-router-dom";
const PasswordLess = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [err, setErr] = useState(false);
    const [registration, setRegistration] = useState(false);
    const [authData, setAuthData] = useState({
        organizationId: "",
        idtoken: "",
        // isRegistered: "False",
        isLoaded: false,
        action: "",
        email: "",
    });

    const [witwayToken, setWitWayToken] = useState();
    const setWitwayToken = (e: any) => {
        setWitWayToken(e);
    };

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Get the 'email' query parameter
    const userEmail = queryParams.get("email");
    const witwaySignature = queryParams.get("witwaySignature");

    const handleRegistration = (e) => {
        setRegistration(e);
    };
    // register
    const register = async () => {
        setIsLoading(true);
        if (authData.organizationId && authData.idtoken) {
            await handleRegisterSubmit(
                authData.organizationId,
                authData.idtoken,
                (e: boolean) => handleRegistration(e)
            );
        }
        setIsLoading(false);
    };
    // end register
    // signin
    const signin = async () => {
        setIsLoading(true);
        if (authData.organizationId && authData.idtoken) {
            await handleSignInSubmit(
                authData.organizationId,
                authData.idtoken,
                (e: any) => setWitwayToken(e)
            );
        }
        setIsLoading(false);
    };
    // end signin

    // get initial auth data
    const getAuthData = async () => {
        try {
            await axios.get(`/account/authdata/${userEmail}`).then(async (res) => {
                if (res.status !== 200) {
                    setErr(true);
                }
                setAuthData((prev) => ({ ...prev, ...res.data, isLoaded: true }));
            });
        } catch (error) {
            // console.log("error from auth data", error);
        }
    };
    const authenticationAction = () => {
        switch (authData.action) {
            case "signin":
            case "reply_guard":
                signin();
                break;
            case "register":
                register();
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        getAuthData();
    }, []);
    useEffect(() => {
        if (authData.isLoaded) authenticationAction();
    }, [authData]);

    useEffect(() => {
        if (registration) signin();
    }, [registration]);

    useEffect(() => {
        if (witwayToken)
            axios
                .get(`/account/authentication?sid=${authData.email}&action=${authData.action}`, {
                    headers: {
                        WitWayToken: witwayToken,
                        witwaySignature: witwaySignature

                    },
                })
                .then((res) => {
                    if (res.status === 200) {
                        setSuccess(true);
                    }
                })
                .finally(() => {
                    setTimeout(() => {
                        window.close();
                    }, 3000);
                });
    }, [witwayToken]);

    return (
        <div>
            {isLoading && <Loading />}
            {success && <Successful action={authData.action} />}
            {err && (
                <div className="vh-100 d-flex justify-content-center align-items-center">
                    Opp's Something went wrong! Please try again.
                </div>
            )}
        </div>
    );
};

export default PasswordLess;
