import * as signalR from "@microsoft/signalr";
import { app } from "@microsoft/teams-js";
import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from "react";

import {
  IParticipant,
  IParticipants,
  IUserInfo,
} from "../types/interface/IParticipant";
import { IAppContext } from "../types/interface/IAppContext";
import axios from "axios";
import { constants } from "../services/constants";
import { loadParticipants } from "../services/participants";
import { getContinentByCountryCode } from "../helper/ContinentHelper";
import { getCountByCountry } from "../helper/participantsInContinentByCountry";
import { countUsersByLocation } from "../helper/countUserByLocation";
import { IOrganizer } from "../types/interface/IOrganizer";
interface AppProviderProps {
  children: ReactNode;
}
const FRAME_CONTEXT_SIDE_PANEL = "sidePanel";
const initialAppContext: IAppContext = {
  appInfo: {
    appLoaded: false,
    // updateAppLoaded: () => {},
    theme: "",
    // updateTheme: (theme: any) => {},
    stageView: false,
    // updateStageView: () => {},
    isFullscreen: false,
    updateFullscreenMode: () => {},
    isMapFullscreen: false,
    updateMapFullscreenMode: () => {},
    viewApp: false,
    updateViewApp: (vApp) => {},
    isAuthenticated: false,
    setIsAuthenticated: (e) => {},
  },
  meetingAndParticipants: {
    meetingInfo: "",
    refreshMeetingInfo: () => {},
    meetingID: "",
    // updateMeetingID: (meetingID: string) => {},
    participants: undefined,
    // updateParticipants: (participants: IParticipants) => {},
    currentUser: undefined,
    // updateCurrentUser: (userInfo: IUserInfo) => {},
    sender: undefined,
    // updateSender: (sender: string) => {},
    currentUserTimezone: "",
    // updateCurrentUserTimezone: (currentTimezone: string) => {},
    participantDetails: undefined,
    updateParticipantDetails: (details: IParticipant, expand: boolean) => {},
    organizer: undefined,
  },
  temperature: {
    isCelsius: true,
    updateTempType: () => {},
  },
  chartData: {
    continentLabels: [],
    updateContinentLabels: (continentLabels: string[]) => {},
    selectedContinent: "",
    updateSelectedContinent: (selectedContinent: string) => {},
    countryUsers: [],
    updateCountryUsers: (countryUsers: string[]) => {},
    countryLabelByContinent: [],
    updateCountryLabelContinent: (countryLabelContinent: number[]) => {},
    countryCountByContinent: [],
    updateCountryCountByContinent: (countryCountByContinent: string[]) => {},
  },
};

const AppContext = createContext<IAppContext>(initialAppContext);

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [viewApp, setViewApp] = useState(false);
  const [appLoaded, setAppLoaded] = useState(false);
  const [theme, setTheme] = useState("");
  const [stageView, setStageView] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isMapFullscreen, setIsMapFullscreen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const [meetingInfo, setMeetingInfo] = useState<any>();
  const [meetingID, setMeetingID] = useState("");
  const [participants, setParticipants] = useState<IParticipants | undefined>(
    undefined
  );
  const [currentUser, setCurrentUser] = useState<IUserInfo | undefined>(
    undefined
  );
  let [sender, setSender] = useState<string>(undefined);

  const [currentUserTimezone, setCurrentUserTimezone] = useState("");
  const [participantDetails, setParticipantDetails] = useState<
    IParticipant | undefined
  >(undefined);

  const [organizer, setOrganizer] = useState<IOrganizer | undefined>(undefined);

  const [isCelsius, setIsCelsius] = useState(true);

  const [continentLabels, setContinentLabels] = useState<string[]>([]);
  const [selectedContinent, setSelectedContinent] = useState("");
  const [countryUsers, setCountryUsers] = useState<string[]>([]);
  const [countryLabelByContinent, setCountryLabelByContinent] = useState<
    number[]
  >([]);
  const [countryCountByContinent, setCountryCountByContinent] = useState<
    string[]
  >([]);

  // app initialization

  const initializeApp = async (retries = 3) => {
    try {
      await app.initialize();
      const context = await app.getContext();
      setTheme(context.app.theme || "default");
      setStageView(
        context.page.frameContext === FRAME_CONTEXT_SIDE_PANEL ? false : true
      );
      setSender(context.user?.id || "unknown");
      setMeetingID(context.meeting?.id || "unknown");
      setCurrentUser(context.user || null);
    } catch (e) {
      console.error(e);

      if (retries > 0) {
        setTimeout(() => initializeApp(retries - 1), 1000); // Retry after 1 second
      } else {
        console.error("SDK initialization failed after retries:", e);
        // Optionally, set default values or notify the user
        setTheme("default");
        setStageView(false);
        setSender("unknown");
        setMeetingID("unknown");
        setCurrentUser(null);
      }
    }
  };

  const setupSignalRConnection = async () => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_ROOT_URL}/notify`)
      .configureLogging(signalR.LogLevel.Information)
      .build();

    connection.onclose(async () => {
      await connection.start();
    });
    connection.on("ReceiveMessage", (message) => {
      handleMessage();
    });

    await connection
      .start()
      .then(async () => {
        try {
          await connection.invoke("JoinGroup", meetingID);
        } catch (error) {
          console.error("Error invoking JoinGroup:", error);
        }
      })
      .catch((err) => {
        console.error(err.message);
      });
  };
  const handleMessage = () => {
    loadAndSetParticipants();
  };

  const loadAndSetParticipants = async () => {
    if (!sender) {
      sender = "guest";
    }
    if (meetingID && sender) {
      try {
        await loadParticipants(meetingID, sender).then((participants) => {
          participants.participants.forEach((item: IParticipant) => {
            item.continent = getContinentByCountryCode(item.countryCode);
          });
          setParticipants(participants);
        });
      } catch (error) {
        console.error("Error loading participants:", error);
      }
    }
  };

  const getMeetingInfo = async () => {
    try {
      await axios.get(constants.GET_MEETING_INFO + meetingID).then((res) => {
        setMeetingInfo(res.data);
      });
    } catch (error) {}
  };
  function setCurrentUserTz() {
    let ctz = participants?.participants?.find(
      (item) => item?.aadID === currentUser?.id
    )?.timeZoneID;
    setCurrentUserTimezone(ctz);
  }

  const handleSelectContinent = (e: string) => {
    try {
      if (participants?.participants && e) {
        setSelectedContinent(e);
        let res = getCountByCountry(participants.participants, e);
        if (res) {
          setCountryLabelByContinent(res.countryLabels);
          setCountryCountByContinent(res.count);
          setCountryUsers(res.usersList);
        }
      }
    } catch (error) {}
  };

  const handleOrganizer = async () => {
    if (meetingID && sender) {
      try {
        await loadParticipants(meetingID, sender)
          .then((res) => {
            let result = res.participants.find(
              (x) => x.role.toLowerCase() === "organizer"
            );
            if (sender === result.aadID) {
              setOrganizer((prev) => ({
                ...prev,
                organizerID: result.aadID,
                organizerEmail: currentUser.userPrincipalName,
                isVerified: result.isVerified,
                hasLocation:
                  result.latitude !== null && result.longitude !== null,
                organizerName: result.userName,
              }));
            }
          })
          .then(() => {
            if (sender) {
              axios
                .get(`${constants.GET_VERIFICATION_CODE}/${sender}`)
                .then((res) => {
                  setOrganizer((prev) => ({
                    ...prev,
                    verificationCode: res.data.isExists,
                    expiryDate: res.data.expiryDate,
                  }));
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          });
      } catch (error) {
        console.error("Error loading participants:", error);
      } finally {
        setOrganizer((prev) => ({
          ...prev,
          isLoaded: true,
        }));
      }
    }
  };

  useEffect(() => {
    initializeApp();
  }, []);
  useEffect(() => {
    if (meetingID !== undefined) {
      setupSignalRConnection();
      loadAndSetParticipants();
      getMeetingInfo();
    }
  }, [meetingID]);
  useEffect(() => {
    handleOrganizer();
  }, [meetingID, sender]);

  useEffect(() => {
    if (meetingInfo) {
      let role = participants?.participants?.filter(
        (item) => item?.aadID === currentUser?.id
      )[0]?.role;
      let isOrganizer =
        role?.trim()?.toLowerCase() === constants.USER_ROLE_ORGANIZER;
      if (participants && !meetingInfo?.enableForAll && !isOrganizer) {
        if (!window.location.href.includes("not-allowed")) {
          window.location.replace("/not-allowed");
        }
      } else {
        setTimeout(() => {
          setAppLoaded(true);
        }, 500);
      }
    }
    setCurrentUserTz();
  }, [meetingInfo, participants]);

  useEffect(() => {
    if (participants) {
      let label = countUsersByLocation(
        participants.participants!,
        "continent"
      )?.map((entry) => entry.users[0].continent);
      setContinentLabels(label);
    }
  }, [participants]);

  useEffect(() => {
    handleSelectContinent(selectedContinent);
  }, [selectedContinent]);

  const value: IAppContext = {
    appInfo: {
      appLoaded,
      theme,
      stageView,
      isFullscreen,
      updateFullscreenMode: () => {
        setIsFullscreen((prev) => !prev);
        setIsMapFullscreen(false);
      },
      isMapFullscreen,
      updateMapFullscreenMode: () => {
        setIsMapFullscreen((prev) => !prev);
        setIsFullscreen(false);
      },
      viewApp,
      updateViewApp: (vApp) => setViewApp(vApp),
      isAuthenticated,
      setIsAuthenticated: (e) => setIsAuthenticated(e),
    },
    meetingAndParticipants: {
      meetingInfo,
      refreshMeetingInfo: () => getMeetingInfo(),
      meetingID,
      participants,
      currentUser,
      sender,
      currentUserTimezone,
      participantDetails,
      updateParticipantDetails: (pDetails, expand) => {
        if (expand === true) {
          setParticipantDetails(pDetails);
        } else {
          if (participantDetails === pDetails) {
            setParticipantDetails(null);
          } else {
            setParticipantDetails(pDetails);
          }
        }
      },
      organizer,
    },
    temperature: {
      isCelsius,
      updateTempType: () => setIsCelsius(!isCelsius),
    },
    chartData: {
      continentLabels,
      updateContinentLabels: (continentLabels) =>
        setContinentLabels(continentLabels),
      selectedContinent,
      updateSelectedContinent: (selectedContinent) =>
        setSelectedContinent(selectedContinent),
      countryUsers,
      updateCountryUsers: (countryUsers) => setCountryUsers(countryUsers),
      countryLabelByContinent,
      updateCountryLabelContinent: (countryLabelByContinent) =>
        setCountryLabelByContinent(countryLabelByContinent),
      countryCountByContinent,
      updateCountryCountByContinent: (countryCountByContinent) =>
        setCountryCountByContinent(countryCountByContinent),
    },
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

// Custom hook to use the AppContext
const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, useAppContext, AppContext };
