import { Spin } from "antd";
import { useAppContext } from "../contexts/AppContext";
const Loading = () => {
  const appCtx = useAppContext();
  const { theme } = appCtx.appInfo;
  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={theme === "dark" ? "theme__dark" : "theme__white"}
    >
      <Spin tip="Loading..."></Spin>
    </div>
  );
};

export default Loading;
